import { DecimalPipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeES from '@angular/common/locales/es';
import localeNL from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MsalGuard, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Chart } from 'chart.js';
import { Driver, NgForageConfig } from 'ngforage';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DateLocalPipe } from './pipes/date-local.pipe';
import { TranslatePipe } from './pipes/translation-pipe';
import { SessionService } from './services/session.service';
import { VersionUpdateService } from './services/version-update.service';

registerLocaleData(localeNL);
registerLocaleData(localeES);

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.clientId,
                authority: environment.authority,
                redirectUri: location.origin + '/farms',
                postLogoutRedirectUri: location.origin + '/farms',
                navigateToLoginRequestUrl: false
            },
            system: {
                iframeHashTimeout: 10000
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            }
        }), {
            interactionType: InteractionType.Redirect,
            // Msal Guard Configuration
            // authRequest: {
            //   scopes: ['user.read', 'api://bf50b183-2d4d-4b9c-8ce0-7ff289c636c3/daa.user.access']
            // }
        }, {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([
            // ['https://graph.microsoft.com/v1.0/me', ['user.read']],
            // ['https://daa-analytics-service-dev.azurewebsites.net/api/*', ['api://bf50b183-2d4d-4b9c-8ce0-7ff289c636c3/daa.user.access']],
            //['http://localhost:7071/api/*', ['api://bf50b183-2d4d-4b9c-8ce0-7ff289c636c3/daa.user.access']],
            ])
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately'
        }),
    ],
    providers: [
        DateLocalPipe,
        TranslatePipe,
        DecimalPipe,
        VersionUpdateService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        MsalGuard,
        MsalService,
        Title,
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: MsalInterceptor,
        //   multi: true
        // }
        {
            provide: LOCALE_ID,
            deps: [SessionService],
            useFactory: (sessionService) => sessionService.getPreferences().language //returns locale string
        }
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule {

  public constructor(ngfConfig: NgForageConfig, sessionService: SessionService) {
    ngfConfig.configure({
      name: 'DAA',
      driver: [Driver.INDEXED_DB]
    })
    Chart.defaults.locale = sessionService.getPreferences().language;
    setTimeout(() => {
        Chart.defaults.datasets.line.spanGaps = true
    }, 300);
  }

}
