export class UserExperience {
    type: EXPERIENCE_TYPES
    id: string

    constructor(type: EXPERIENCE_TYPES, id: string) {
        this.type = type
        this.id = id
    }
}

export enum EXPERIENCE_TYPES {
    AHV = 'ahv',
    FARM_TRACE_HEALTH = 'farm_trace_health',
    FARM_TRACE_FEED = 'farm_trace_feed',
    TN_ES = 'nutreco_es',
    TN_US = 'nutreco_us',
    NANTA = 'nanta'
  }
