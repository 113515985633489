import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import moment from 'moment';
import { NgForage } from 'ngforage';
import { Observable, Subscriber, filter } from 'rxjs';
import { Preferences } from 'src/app/models/preferences';
import { SessionService } from 'src/app/services/session.service';
import { environment } from 'src/environments/environment';
import { Farm } from '../models/farm';
import { FeedFarm } from '../models/feedFarm';
import { ConfirmAdvice } from './../models/confirmAdvice';

enum API_METHOD {
  GET,
  POST,
  PUT,
  DELETE,
}

class CACHE {
  preferences: any = {}
  farmList: any = []
  farms: CACHE_FARM[] = []
}

class CACHE_FARM {
  ubn: string
  syncDate: string
  mprDates: string[] = []
  mpr: any = {}
  advices: any = []
  solutions: any = []
  animals: any = []
}

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  readonly request = { scopes: ['api://' + environment.clientId + '/daa.user.access'] }
  readonly fakeOffline = false

  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private readonly ngf: NgForage,
    private session: SessionService
  ) {

    // TEMP


    // this.ngf.clear()

    // this.ngf.keys().then(r => {
    //   r.forEach(r => {
    //     this.ngf.getItem(r).then(r => { console.log('CACHE', r) })
    //   })
    // })




    // END TEMP

  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // API FUNCTIONS
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private getHeaders(token: any): Headers {
    const headers = new Headers()
    headers.append('Authorization', 'Bearer ' + token.accessToken)
    headers.append('Content-Type', 'application/json')
    return headers
  }

  private getFormHeaders(token: any): Headers {
    const headers = new Headers()
    headers.append('Authorization', 'Bearer ' + token.accessToken)
    return headers
  }


  private async api(url: string, method: API_METHOD, params: any = {}, body = null, data: FormData = null) {
    if (url != "Preferences") params["groupId"] = SessionService.experience.value.id
    if (params) url += "?" + new URLSearchParams(params)

    var token: AuthenticationResult

    if (this.authService.instance.getAllAccounts().length > 0) {
      this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0])
    }else{
      this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe(() => {
        this.authService.instance.loginRedirect();
      })
    }

    await this.authService.instance.acquireTokenSilent(this.request).then(r => {
      token = r
    }).catch((e) => {
      console.log(e);
    })

    if (!token){
      this.AcquireTokenRedirect();
    }

    var b = body ? JSON.stringify(body) : data ? data : null;

    if (!this.fakeOffline)
      try {
        return fetch(environment.apiEndpoint + url, {
          method: API_METHOD[method],
          headers: data ? this.getFormHeaders(token) : this.getHeaders(token),
          cache: 'no-cache',
          body: b
        })
      } catch { }
  }

  private AcquireTokenRedirect() {
      this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe(() => {
        this.authService.acquireTokenRedirect(this.request);
      })
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Utils
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async getPreferences(): Promise<any> {
    return this.api('Preferences', API_METHOD.GET).then(r => {
      return r.json() || {}
    })
  }

  async getConfiguration(key: string) {
    return this.api('Configuration', API_METHOD.GET, { key: key }).then(r => {
      return r.ok ? (r.json() || {}) : r.status.toString()
    })
  }

  async getFeatureFlag(key: string) {
    return this.api('FeatureFlag', API_METHOD.GET, { key: key }).then(r => {
      return r.ok ? (r.json() || {}) : r.status.toString()
    })
  }

  async SavePreferences(preferences: Preferences) {
    return this.api('Preferences', API_METHOD.POST, null, preferences).then(r => {
      return r.ok ? r.json() as Promise<any> : r.status.toString()
    })
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // AHV
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////


  async getFarms(): Promise<any> {
    return this.api('FarmList', API_METHOD.GET).then(async r => {
      var result: string | any[]
      if (r.ok) {
        result = (await r.json() as Farm[] || [])
        result.map((f: { experience: string; }) => f.experience = SessionService.experience.value.type.valueOf())
      } else
        result = r.status.toString()
      return result
    })
  }

  async getMprDates(ubn: string): Promise<any> {
    return this.api('MprDates', API_METHOD.GET, { ubn: ubn }).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async getAdviceDates(ubn: string): Promise<any> {
    return this.api('FarmAdviceDates', API_METHOD.GET, { ubn: ubn }).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async getMpr(ubn: string): Promise<any> {
    return this.api('FarmOverviewMPR', API_METHOD.GET, { ubn: ubn }).then(r => {
      return r.ok ? (r.json() || {}) : r.status.toString()
    })
  }

  async getAdvices(ubn: string, date: string): Promise<any> {
    return this.api('FarmAdvice', API_METHOD.GET, { ubn: ubn, advicedate: moment(date).format('YYYY-MM-DD') }).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async getAnimalAdvices(ubn: string, date: string, lifenumber: string): Promise<any> {
    return this.api('FarmAdvice', API_METHOD.GET, { ubn: ubn, advicedate: moment(date).format('YYYY-MM-DD'), lifenumber: lifenumber }).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async getAnimal(ubn: string, lifenumber: string): Promise<any> {
    return this.api('AnimalProfile', API_METHOD.GET, { ubn: ubn, lifenumber: lifenumber }).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async getAnimalList(ubn: string): Promise<any> {
    return this.api('AnimalList', API_METHOD.GET, { ubn: ubn }).then(r => {
      return r.ok ? (r.json() || []) : []
    })
  }


  async getSolutionHistory(ubn: string, lifenumber: string) {
    return this.api('SolutionHistory', API_METHOD.GET, { ubn: ubn, lifenumber: lifenumber }).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async getTreatmentHistory(ubn: string, recentTreatments: boolean = false) {
    return this.api('TreatmentHistory', API_METHOD.GET, { ubn: ubn, FilterRecentAppTreatments: recentTreatments }).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async getAvailableSolutions() {
    return this.api('SolutionProductListGrouped', API_METHOD.GET).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async getAvailableProducts() {
    return this.api('ProductList', API_METHOD.GET).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async getAllProducts() {
    return this.api('ManualProductListGrouped', API_METHOD.GET).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async getTreatmentReasons() {
    return this.api('ProductAdmReasonListGrouped', API_METHOD.GET).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async SaveManualSolution(solution: any[]) {
    return this.api('AdmSolution', API_METHOD.POST, {}, solution).then(r => {
      return r.ok as unknown as Promise<boolean>
    })
  }

  async SaveManualSolutions(solutions: any[]) {
    return this.api('AdmSolutionBulk', API_METHOD.POST, {}, solutions).then(r => {
      return r.ok as unknown as Promise<boolean>
    })
  }

  async getHerdHealthGroupings() {
    return this.api('HerdHealthGroupings', API_METHOD.GET).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async getHerdHealthComparisonOverview(ubn: string, mprDate: string, groupingId: number, filterParityIds: string = null, filterDILRangeIds: string = null, filterCategoryIds: string = null) {
    if (!filterParityIds) filterParityIds = '0'
    if (!filterDILRangeIds) filterDILRangeIds = '0'
    if (!filterCategoryIds) filterCategoryIds = '0'
    return this.api('HerdHealthComparisonOverview', API_METHOD.GET, { ubn: ubn, mprDate: moment(mprDate).format('YYYY-MM-DD'), groupingId: groupingId, filterParityIds: filterParityIds, filterDILRangeIds: filterDILRangeIds, filterCategoryIds: filterCategoryIds }).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async getHerdHealthIndividualsMilkData(ubn: string, mprDate: string, groupingId: number, itemNr: number, filterParityIds: string = null, filterDILRangeIds: string = null, filterCategoryIds: string = null) {
    if (!filterParityIds) filterParityIds = '0'
    if (!filterDILRangeIds) filterDILRangeIds = '0'
    if (!filterCategoryIds) filterCategoryIds = '0'
    return this.api('HerdHealthIndividualsMilkData', API_METHOD.GET, { ubn: ubn, mprDate: moment(mprDate).format('YYYY-MM-DD'), groupingId: groupingId, itemNr: itemNr, filterParityIds: filterParityIds, filterDILRangeIds: filterDILRangeIds, filterCategoryIds: filterCategoryIds }).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }


  async getHerdHealthMprPerformance(ubn: string, mprDate: string, groupingId: number, rangeId: number, filterParityIds: string = null, filterDILRangeIds: string = null, filterCategoryIds: string = null) {
    if (!filterParityIds) filterParityIds = '0'
    if (!filterDILRangeIds) filterDILRangeIds = '0'
    if (!filterCategoryIds) filterCategoryIds = '0'
    return this.api('HerdHealthMprPerformance', API_METHOD.GET, { ubn: ubn, mprDate: moment(mprDate).format('YYYY-MM-DD'), groupingId: groupingId, rangeId: rangeId, filterParityIds: filterParityIds, filterDILRangeIds: filterDILRangeIds, filterCategoryIds: filterCategoryIds }).then(r => {
      return r.ok ? (r.json() || []) : r.status.toString()
    })
  }

  async confirmAdvices(ubn: string, advices: ConfirmAdvice[]) {
    return this.api('ConfirmAdvice', API_METHOD.POST, { ubn: ubn }, advices).then(r => {
      return r.ok ? r.json() as Promise<any> : r.status.toString()
    })
  }

  async CaReport(ubn: string) {
    return this.api('CaReport', API_METHOD.POST, { ubn: ubn }).then(r => {
      return r.ok ? true as unknown as Promise<any> : false as unknown as Promise<any>
    })
  }

  async DeleteDAATreatments(treatmentsIds: number[]) {
    return this.api('DeleteTreatmentBulk', API_METHOD.DELETE, { treatmentIds: treatmentsIds }).then(r => {
      return r.ok as unknown as Promise<boolean>
    })
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // D4F
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async getFeedFarms(recency: string): Promise<any> {
    return this.api('FarmListFeed', API_METHOD.GET, { dataRecency: recency }).then(async r => {
      var result: string | any[]
      if (r.ok) {
        result = (await r.json() as FeedFarm[] || [])
        result.map((f: { experience: string; }) => f.experience = SessionService.experience.value.type.valueOf())
      } else
        result = r.status.toString()
      return result
    })
  }

  async getD4fGroupings(): Promise<any> {
    return this.api('HHD4FGroupings', API_METHOD.GET).then(r => {
      return r.ok ? r.json() : []
    })
  }

  async getD4fFarmOverview(ubn: string, dataRecency: string, metric: string, filterParityIds: number[],
    filterPhaseIds: number[]) {
    var queryParams = {
      ubn: ubn,
      dataRecency: dataRecency,
      metric: metric
    }
    if (filterParityIds.length > 0) queryParams["filterParityIds"] = filterParityIds
    if (filterPhaseIds.length > 0) queryParams["filterPhaseIds"] = filterPhaseIds
    return this.api('FarmOverviewD4F', API_METHOD.GET, queryParams).then(r => {
      return r.ok ? (r.json() || undefined) : r.status.toString()
    })
  }

  async getD4fHerdComparison(ubn: string, daysData: number, dataRecency: string, grouping: string,
    aggregation: string, metric: string, figure: string, filterParityIds: number[], filterPhaseIds: number[], allCows: boolean): Promise<any> {
    var queryParams = {
      ubn: ubn,
      daysData: daysData,
      dataRecency: dataRecency,
      grouping: grouping,
      aggregation: aggregation,
      metric: metric,
      figure: figure,
      all: allCows
    }
    if (filterParityIds.length > 0) queryParams["filterParityIds"] = filterParityIds
    if (filterPhaseIds.length > 0) queryParams["filterPhaseIds"] = filterPhaseIds
    return this.api('HHD4FComparisonView', API_METHOD.GET, queryParams).then(r => {
      return r.ok ? (r.json() || undefined) : r.status.toString()
    })
  }

  async getD4fHerdHistory(ubn: string, dataToDisplay: string[], dataRecency: string, grouping: string,
    aggregation: string, metric: string, axis: string, filterParityIds: number[], filterPhaseIds: number[], allCows: boolean): Promise<any> {
    var queryParams = {
      ubn: ubn,
      dataToDisplay: dataToDisplay,
      xAxis: axis,
      dataRecency: dataRecency,
      grouping: grouping,
      aggregation: aggregation,
      metric: metric,
      all: allCows

    }
    if (filterParityIds.length > 0) queryParams["filterParityIds"] = filterParityIds
    if (filterPhaseIds.length > 0) queryParams["filterPhaseIds"] = filterPhaseIds
    return this.api('HHD4FHistoryView', API_METHOD.GET, queryParams).then(r => {
      return r.ok ? (r.json() || undefined) : r.status.toString()
    })
  }

  async uploadFile(ubn: string, fileTemplateKey: number, formData: FormData, verifyOnly: boolean = false) {
    return this.api('UploadFile', API_METHOD.POST, { ubn, fileTemplateKey, verifyOnly }, null, formData).then(r => {
      return r.ok ? r.json() as Promise<any> : r.status.toString();
    });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CACHING
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  syncData(): Observable<any> {
    var obs = new Observable<any>((observer): any => {
      var cache = new CACHE()
      this.ngf.getItem('cache').then((r) => { if (r) cache = r as CACHE })
      this.syncFarms(cache, observer)
    })
    return obs
  }

  private async syncFarms(cache: CACHE, observer: Subscriber<any>) {
    cache.preferences = await this.getPreferences()
    cache.farmList = await this.getFarms()
    for (var i = 0; i < cache.farmList.length; i++) {
      await this.syncFarm(cache, cache.farmList[i].ubn, observer)
    }
  }

  private async syncFarm(cache: CACHE, ubn: string, observer: Subscriber<any>) {
    var farm = new CACHE_FARM()
    farm.ubn = ubn
    farm.mprDates = await this.getAdviceDates(farm.ubn)
    if (farm.mprDates.length > 0) {
      farm.mpr = await this.getMpr(farm.ubn)
      farm.advices = await this.getAdvices(farm.ubn, farm.mprDates[0])
      farm.animals = await this.getAnimalList(farm.ubn)
      farm.solutions = await this.getSolutionHistory(farm.ubn, null)
      this.storeFarmCache(cache, farm, observer)
    }
    else {
      this.storeFarmCache(cache, farm, observer)
    }
  }

  private storeFarmCache(cache: CACHE, farm: CACHE_FARM, observer: Subscriber<any>) {
    farm.syncDate = moment().format('YYYY-MM-DDTHH:mm:ss')
    var f = cache.farms.find(i => i.ubn == farm.ubn)
    if (f) {
      cache.farms[cache.farms.findIndex(i => i.ubn == farm.ubn)] = farm
    } else {
      cache.farms.push(farm)
    }
    this.ngf.setItem('cache', cache)
    observer.next({ ubn: farm.ubn, syncDate: farm.syncDate })
  }

}