import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PreloadAllModules, RouterModule, ROUTES } from '@angular/router';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { EXPERIENCE_TYPES, UserExperience } from './models/user-experience';
import { ExperienceService } from './services/experience.service';
import { SessionService } from './services/session.service';

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot([], {preloadingStrategy: PreloadAllModules, initialNavigation: !isIframe ? 'enabled' : 'disabled' })
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      useFactory: configModules,
      multi: true
    }
  ]
})
export class AppRoutingModule {
  constructor(
    private auth: MsalService,
    private experience: ExperienceService,
    private title: Title){

      
    this.auth.instance.addEventCallback((event) => {
      if (this.auth.instance.getAllAccounts().length > 0) {
        this.auth.instance.setActiveAccount(this.auth.instance.getAllAccounts()[0])
      }
    });
    
    this.auth.instance.handleRedirectPromise().then(result => {
      if (result) {
          this.ActivateAccount(result);
      } else{
        const currentAccounts = this.auth.instance.getAllAccounts();

        if (!currentAccounts || currentAccounts.length < 1) {
            return;
        } else {
            const activeAccount = currentAccounts[0];

            this.ActivateAccount(activeAccount);
        }
      }
    })
  }

  private ActivateAccount(result) {
    this.auth.instance.setActiveAccount(result.account);

    if (!SessionService.experience.value.id) {
      this.experience.setActiveAffiliation(this.auth.instance.getActiveAccount()?.idTokenClaims);
      window.location.reload();
    }

    SessionService.experience.subscribe(x => {
      // set the app title and favicon to the experience specific values, or default
      switch (x.type) {
        case EXPERIENCE_TYPES.AHV:
          this.title.setTitle('AHV Farm App');
          break;

        default:
          // default is already set in index.html
          break;
      }

      // set up dyanamic app manifest so users who install app to their phones will see appropriate name/icon
      this.setManifest(x);
    });
  }

  setManifest(experience: UserExperience){
    let manifest;
    // url Prefix is needed - relative urls in manifest wont work with the data url
    let urlPrefix = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/";
    switch (experience.type) {
      case EXPERIENCE_TYPES.AHV:
        manifest = {
          "name": "AHV Farm App",
          "short_name": "AHV Farm App",
          "theme_color": "#408A9B",
          "background_color": "#fafafa",
          "display": "standalone",
          "        ": "/",
          "start_url": urlPrefix + "index.html",
          "icons": [
            {
              "src": urlPrefix +  "assets/icons/icon_ahv-72x72.png",
              "sizes": "72x72",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon_ahv-96x96.png",
              "sizes": "96x96",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon_ahv-128x128.png",
              "sizes": "128x128",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon_ahv-144x144.png",
              "sizes": "144x144",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon_ahv-152x152.png",
              "sizes": "152x152",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon_ahv-192x192.png",
              "sizes": "192x192",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon_ahv-384x384.png",
              "sizes": "384x384",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon_ahv-512x512.png",
              "sizes": "512x512",
              "type": "image/png",
              "purpose": "maskable any"
            }
          ]
        }
        break;

      default:
        manifest = {
          "name": "DairyAnimalAdviserApp",
          "short_name": "DAA",
          "theme_color": "#108C9D",
          "background_color": "#fafafa",
          "display": "standalone",
          "        ": "/",
          "start_url": urlPrefix + "index.html",
          "icons": [
            {
              "src": urlPrefix +  "assets/icons/icon-72x72.png",
              "sizes": "72x72",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon-96x96.png",
              "sizes": "96x96",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon-128x128.png",
              "sizes": "128x128",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon-144x144.png",
              "sizes": "144x144",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon-152x152.png",
              "sizes": "152x152",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon-192x192.png",
              "sizes": "192x192",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon-384x384.png",
              "sizes": "384x384",
              "type": "image/png",
              "purpose": "maskable any"
            },
            {
              "src": urlPrefix +  "assets/icons/icon-512x512.png",
              "sizes": "512x512",
              "type": "image/png",
              "purpose": "maskable any"
            }
          ]
        }
        break;
    }
    // set new manifest file to head of document w/ data url
    let content = encodeURIComponent(JSON.stringify(manifest));
    let url = "data:application/manifest+json,"+content;
    let element = document.createElement('link');
    element.setAttribute('rel', 'manifest');
    element.setAttribute('href', url);
    document.querySelector('head').appendChild(element);
  }
}

function configModules() {
  switch (SessionService.experience.value?.type) {
    case EXPERIENCE_TYPES.NANTA:
    case EXPERIENCE_TYPES.TN_ES:
    case EXPERIENCE_TYPES.TN_US:
    case EXPERIENCE_TYPES.FARM_TRACE_FEED:
      return [{
          path:'',
          CanActivateChild: [MsalGuard],
          loadChildren: () => import('./dairy4feed.module').then(m => m.Dairy4FeedModule)
        }]
    default:
      return [{
        path:'',
        CanActivateChild: [MsalGuard],
        loadChildren: () => import('./dairyadvisor.module').then(m => m.DairyAdvisorModule)
      }]
  }
}
