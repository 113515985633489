import { Injectable } from "@angular/core";
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from './session.service';

export enum FLAG_KEYS {
    MTE = 'ManualTreatment',
    MTE_MULTI = 'ManualTreatmentMulti',
    EXPERIENCE ='ExperienceModule',
    NEW_TOP_NAV = 'NewTopNav',
    HIDE_HERD_HEALTH = 'HideHerdHealth',
    GENERATE_CA_BUTTON = 'GenerateCARepButton'
}

class FeatureFlag {
    key: string
    value: boolean
}

@Injectable({
    providedIn: 'root'
})

  export class FeatureFlagService {

    private experienceFlag = this.api.getFeatureFlag(FLAG_KEYS.EXPERIENCE)
    private topNavFlag = this.api.getFeatureFlag(FLAG_KEYS.NEW_TOP_NAV)

    constructor(private api: ApiService, private session: SessionService) {}

    isFeatureFlagEnabled(key: FLAG_KEYS) {
        return this.session.getFeatureFlag(key) ? this.session.getFeatureFlag(key).value : false
    }

    async getFeatureFlags() {
        const mteFlag = this.api.getFeatureFlag(FLAG_KEYS.MTE)
        const mteMultiFlag = this.api.getFeatureFlag(FLAG_KEYS.MTE_MULTI)
        const hhFlag = this.api.getFeatureFlag(FLAG_KEYS.HIDE_HERD_HEALTH)
        const caBtnFlag = this.api.getFeatureFlag(FLAG_KEYS.GENERATE_CA_BUTTON)
        await Promise.all([this.experienceFlag, this.topNavFlag, mteFlag, mteMultiFlag, hhFlag, caBtnFlag]).then(flags => {
            const flagsMapped = flags.map(flag => flag as FeatureFlag)
            this.session.setFeatureFlags(flagsMapped)
        })
    }
  }