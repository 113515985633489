import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";

@Injectable()
export class VersionUpdateService{

    constructor(private updates: SwUpdate) {
    if (updates.isEnabled) {
        setInterval(() => updates.checkForUpdate(),6 * 60 * 60);
        }
    }

    public checkForUpdates(): void {
        this.updates.available.subscribe(event => this.promptUser());
    }

    private promptUser(): void {
        this.updates.activateUpdate().then(() => document.location.reload());
    }
}