import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FeatureFlag } from '../models/configuration';
import { Farm } from '../models/farm';
import { Preferences } from '../models/preferences';
import { UserExperience } from '../models/user-experience';
import { FLAG_KEYS } from './flagging.service';

@Injectable({
  providedIn: 'root'
})

export class SessionService {
  public static experience: BehaviorSubject<UserExperience> = new BehaviorSubject(JSON.parse(localStorage.getItem('user_experience') || '{}') as UserExperience)
  public static farm: BehaviorSubject<Farm> = new BehaviorSubject(JSON.parse(localStorage.getItem('farm') || '{}') as Farm)

  constructor() {
  }

  setPreferences(preferences: Preferences) {
    if (!preferences.language || (preferences.language != 'en' && preferences.language != 'nl' && preferences.language != 'es'))
      preferences.language = 'nl'

    if (!preferences.dateFormat || (preferences.dateFormat != 'dd-MMM-yyyy' && preferences.dateFormat != 'MMM-dd-yyyy'))
      preferences.dateFormat = 'dd-MMM-yyyy'

    if (!preferences.unit || (preferences.unit != 'Value' && preferences.unit != 'Percentage'))
      preferences.unit = 'Value'

    localStorage.setItem('preferences', JSON.stringify(preferences))
  }

  getPreferences(): Preferences {
    let localPref = JSON.parse(localStorage.getItem('preferences'));
    // if there are no preferences saved locally, set the defaults
    if (!localPref) {
      this.setPreferences(new Preferences)
      return JSON.parse(localStorage.getItem('preferences')) as Preferences
    } else {
      return localPref as Preferences;
    }
  }

  setFarm(farm: Farm) {
    localStorage.setItem('farm', JSON.stringify(farm))
    SessionService.farm.next(farm)
  }

  setFarmsList(farms: Farm[]) {
    localStorage.setItem('farmsList', JSON.stringify(farms))
  }

  setUserExperience(userExperience: UserExperience) {
    localStorage.setItem('user_experience', JSON.stringify(userExperience))
    SessionService.experience.next(userExperience)
  }

  setFeatureFlags(flags: FeatureFlag[]) {
    localStorage.setItem('ft_flags', JSON.stringify(flags))
  }

  setLanguage(language: string) {
    localStorage.setItem('language', language)
  }

  setUnitComparison(unitComparison: string) {
    localStorage.setItem('unitComparison', unitComparison)
  }

  getFarmsList() {
    const farmsList = localStorage.getItem('farmsList')
    return farmsList ? JSON.parse(farmsList) as Farm[] : []
  }

  getFeatureFlag(key: FLAG_KEYS = null): FeatureFlag {
    let ft_flags: any = localStorage.getItem('ft_flags')
    if (!ft_flags) return ft_flags
    ft_flags = JSON.parse(ft_flags) as FeatureFlag[]
    return ft_flags.find(f => f.key === key) as FeatureFlag
  }

  getLanguage() {
    return localStorage.getItem('language')
  }

  getUnitComparison() {
    return localStorage.getItem('unitComparison')
  }

}
