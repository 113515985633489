import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { FeatureFlagService } from './services/flagging.service';
import { MenuService } from './services/menu-service';
import { VersionUpdateService } from './services/version-update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  menuItems = []
  active: string = ''

  constructor(
    private authService: MsalService,
    public menuservice: MenuService,
    private featureFlagService: FeatureFlagService,
    private updateService: VersionUpdateService
  ) { }

  async ngOnInit() {
    this.menuservice.name = this.authService.instance.getActiveAccount()?.name    
    await this.featureFlagService.getFeatureFlags();
    this.menuItems = this.menuservice.getMenuItems()

    if(environment.production)
      this.updateService.checkForUpdates()
  }

  ngAfterViewInit() {
    this.menuservice.getActive().subscribe((r) => {
      this.active = r
    })
  }

  navigateTo(url: string, queryParams: any = undefined) {
    this.menuservice.navigateTo(url, queryParams)
  }
}
