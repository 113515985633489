import { Pipe, PipeTransform } from "@angular/core";
import translation from '../../assets/translation/translation.json';
import { SessionService } from "../services/session.service";

@Pipe({
    name: 'tl',
    pure: false
})

export class TranslatePipe implements PipeTransform {

    constructor(
        private session: SessionService
    ) { }

    transform(input: string, key: string = undefined): string {
        if (!input || input.trim() == '') {
            return input
        }

        var lang = this.session.getPreferences()?.language
        var value = key ? key : input.trim()
        if (lang == 'undefined' || !lang)
            return input

        // var tlClone = _.cloneDeep(translation)
        // var tlLower = _.transform(tlClone, function (result, val, key) {
        //     result[key.toLowerCase()] = val
        // })
        // var obj = tlLower[value.toLowerCase()]



        var obj = translation[value]

        if (!obj) {
            console.warn(`tl with key [${value}] not found.`)
        } else {
            var tl
            if (lang && lang != 'en') {
                tl = obj[lang]
            } else {
                if (key) return input
                if (obj[lang]) {
                    tl = obj[lang]
                } else {
                    tl = value
                }
            }
            if (!tl) {
                console.warn(`tl for [${lang}] in [${value}] not found.`)
            }
            else {
                value = tl
            }
        }
        return value
    }
}