import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../services/session.service';

@Pipe({
  name: 'dateLocal',
  pure: false
})

export class DateLocalPipe implements PipeTransform {

  constructor(private session: SessionService) { }

  transform(value: string | number | Date, chart: boolean = false): string {
    return new DatePipe(this.session.getPreferences().language).transform(value, chart? 'MMM yyyy' : ( this.session.getPreferences().dateFormat || 'MM-dd-yyyy'))
  }

}
