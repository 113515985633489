import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { EXPERIENCE_TYPES } from '../models/user-experience';
import { TranslatePipe } from '../pipes/translation-pipe';
import { FeatureFlagService, FLAG_KEYS } from './flagging.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})

export class MenuService {
  private readonly experience = SessionService.experience.value.type

  private _active$ = new Subject<string>()

  name: string
  active: string
  getActive(): Observable<string> {
    return this._active$.asObservable()
  }

  constructor(
    private menu: MenuController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private flagService: FeatureFlagService,
    private tl: TranslatePipe
  ) {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        this.active = this.tl.transform(this.activatedRoute.firstChild.firstChild.snapshot.data['menu'])
        this._active$.next(this.active)
      }
    })
  }

  getMenuItems() {
    var menuItemDefault = [
      { text: this.tl.transform('Farm Profile'), url: '/farms/:ubn' }
    ]
    switch (this.experience) {
      case EXPERIENCE_TYPES.NANTA:
      case EXPERIENCE_TYPES.TN_ES:
      case EXPERIENCE_TYPES.TN_US:
      case EXPERIENCE_TYPES.FARM_TRACE_FEED:
        return menuItemDefault.concat(
          [
            { text: this.tl.transform('Herd Health'), url: '/farms/:ubn/herd-comparison' },
            { text: this.tl.transform('Herd History'), url: '/farms/:ubn/herd-history' },
          ]
        )
      default:
        let items = menuItemDefault.concat(
          [
            { text: this.tl.transform('Animals'), url: '/farms/:ubn/animal-search' },
            { text: this.tl.transform('Herd Health'), url: '/farms/:ubn/herd-health' },
            { text: this.tl.transform('Advice'), url: '/farms/:ubn/animals' },
            { text: this.tl.transform('Treatments'), url: '/farms/:ubn/treatments'}
          ]
        )
        if (this.flagService.isFeatureFlagEnabled(FLAG_KEYS.HIDE_HERD_HEALTH)) {
          items = items.filter(item => !item.text.includes('Herd Health'))
        }

        if (!this.flagService.isFeatureFlagEnabled(FLAG_KEYS.MTE_MULTI)) {
          items = items.filter(item => !item.text.includes('Treatments'))
        }
        return items
    }
  }

  async openSidemenu() {
    this.menu.enable(true, 'mainSidemenu').then(() => {
      this.menu.open('mainSidemenu')
    })
  }

  async navigateTo(url: string, queryParams: any) {
    url = url.replace(':ubn', SessionService.farm.value.ubn)
    this.router.navigate([url], { queryParams: queryParams }).then((r) => {
      this.menu.close('mainSidemenu')
    })
  }

}