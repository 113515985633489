import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from "@angular/core";
import { SessionService } from 'src/app/services/session.service';
import { GroupIds } from '../constants/group-ids';
import { EXPERIENCE_TYPES, UserExperience } from '../models/user-experience';

@Injectable({
    providedIn: 'root'
})
export class ExperienceService {

    imgSource: string

    constructor(@Inject(DOCUMENT) private doc: Document, private session: SessionService) {
        SessionService.experience.subscribe(value => {
            this.setExperienceStyling(value)
        })
    }

    setActiveAffiliation(claims: object) {
        const groups = claims ? claims["groups"] as string[] : [];

        switch (groups.length > 0) {
            case groups.includes(GroupIds.AHVUsers) && !groups.includes(GroupIds.FarmTraceHealthUsers):
                this.session.setUserExperience(new UserExperience(EXPERIENCE_TYPES.AHV, GroupIds.AHVUsers))
                break;
            case groups.includes(GroupIds.NantaUsers) && !groups.includes(GroupIds.FarmTraceHealthUsers):
                this.session.setUserExperience(new UserExperience(EXPERIENCE_TYPES.NANTA, GroupIds.NantaUsers))
                break;
            default:
                const experience = SessionService.experience.value
                this.session.setUserExperience('in' in experience ? experience : new UserExperience(EXPERIENCE_TYPES.FARM_TRACE_HEALTH, GroupIds.FarmTraceHealthUsers))
                break;
        }
    }

    private setExperienceStyling(userexperience: UserExperience) {
        this.doc.documentElement.setAttribute('experience', userexperience.type);
        switch (userexperience.type) {
            case EXPERIENCE_TYPES.AHV:
                (this.doc.querySelector('#favicon') as HTMLLinkElement).href = "../../assets/icon/favicon_ahv.png";
                this.imgSource = "../../assets/logo/logo_ahv.svg"
                break
            default:
                (this.doc.querySelector('#favicon') as HTMLLinkElement).href = "../../assets/icon/favicon_ft.png";
                this.imgSource = "../../assets/logo/logo_farmtrace.png";
                break
        }
    }
}